exports.onRouteUpdate = ({ location, prevLocation }) => {
  const matchExamBackNavigation =
    (location.pathname === '/exames' || location.pathname === '/exames/') &&
    prevLocation &&
    /\/exames\//gi.test(prevLocation.pathname)
  const matchVaccineBackNavigation =
    (location.pathname === '/vacinas' || location.pathname === '/vacinas/') &&
    prevLocation &&
    /\/vacinas\//gi.test(prevLocation.pathname)

  if (matchExamBackNavigation || matchVaccineBackNavigation)
    localStorage.setItem('trigger-search', 'true')

  const hasInstagramEmbed = document.querySelector('.instagram-media')

  if (hasInstagramEmbed) {
    const script = document.createElement('script')
    script.src = 'https://www.instagram.com/embed.js'
    script.async = true
    document.body.appendChild(script)
  }
}
